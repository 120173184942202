module.exports = {
    title: "Aurore Osteo",
    dbname: "auroredefienne",
    options: {
        sendReports: true,
        newsRequests: true,
        checkupReminders: {
            intervals: [ "annual", "be-annual", "none" ],
            default: "annual"
        }
    },
    search: {
        fields: {
            clients: [ "name", "email", "phone" ],
            animals: [ "name" ]
        }
    },
    animals: {
        privateNotes: false,
        sterilized: false,
        veterinary: false
    },
    sessions: {
        numberFormat: "CR{date}_{animalName}_{uniqId}",
        numberFormatDateFormat: "yyyyMMdd",
        drawnEditor: {
            defaultShapeProps: {
                stroke: {
                    color: "#3949ab"
                }
            }
        }
    },
    theme: {
        palette: {
            primary: {
                main: "#3949ab",
                mainContrastText: "#fff",
                dark: "#1a237e",
                darkContrastText: "#fff",
            },
            secondary: {
                main: "#7986cb",
                dark: "#566096",
                contrastText: "#fff",
            },
            cancel: {
                main: "#757575",
                dark: "#616161",
                contrastText: "#fff",
            },
        },
    }
}